import * as React from "react"
import {useStaticQuery, graphql} from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import * as Styles from "../styles/access.module.scss"

const AccessPage = () => {
  const DATA = useStaticQuery(
      graphql`
        query {
          site {
              siteMetadata  {
                divisionJa { gyosei },
                menuJa { access },
                name,
                no,
                address { postalCode, address },
                tel { print, code, receptionHours },
                gmapAddress
              }
          }
        }
      `
    )
  const GYOSEI_JA = DATA.site.siteMetadata.divisionJa.gyosei
  const ACCESS_JA = DATA.site.siteMetadata.menuJa.access

  return (
    <Layout division='gyosei'>
        <Seo title={`${ACCESS_JA} | ${GYOSEI_JA}`} />
        <h1 className="gyosei">{ACCESS_JA}</h1>

        <div className={`${Styles.gyosei} ${Styles.table}`}>
            <div className={Styles.table_body}>
                <div className={Styles.table_col}>
                    <span className={Styles.table_key}>事務所名</span>
                    <span className={Styles.table_val}>{GYOSEI_JA}</span>
                </div>
                <div className={Styles.table_col}>
                    <span className={Styles.table_key}>行政書士</span>
                    <span className={Styles.table_val}>{DATA.site.siteMetadata.name}
                        <span className={Styles.small_letters}>(登録番号 {DATA.site.siteMetadata.no})</span>
                    </span>
                </div>
                <div className={Styles.table_col}>
                    <span className={Styles.table_key}>住所</span>
                    <span className={Styles.table_val}>{DATA.site.siteMetadata.address.postalCode}<br />{DATA.site.siteMetadata.address.address}</span>
                </div>
                <div className={Styles.table_col}>
                    <span className={Styles.table_key}>電話番号</span>
                    <span className={Styles.table_val}>
                      <a href={`tel:${DATA.site.siteMetadata.tel.code}`}>{DATA.site.siteMetadata.tel.print}</a><br />
                      <span className={Styles.small_letters}>{DATA.site.siteMetadata.tel.receptionHours}</span>
                    </span>
                </div>
                <div className={Styles.table_col}>
                    <span className={Styles.table_key}>取扱業務</span>
                    <span className={Styles.table_val}>会計記帳<br />婚前契約書作成支援<br />遺言書作成支援<br />各種書類作成</span>
                </div>
            </div>
        </div>

        <div className={Styles.ggmap}>
            <iframe src={DATA.site.siteMetadata.gmapAddress} loading="lazy"></iframe>
        </div>
    </Layout>
  )
}

export default AccessPage
